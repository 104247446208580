import React from 'react'
import { Link } from 'gatsby'

import logo from '../images/logo.png'

const Header = () => (
  <header className="header">
    <div>
      <Link to="/" title="Bergamot logo">
        <img className="header__logo" src={logo} alt="Bergamot Title" />
      </Link>
    </div>
    <div>
      <div className="field is-grouped header__buttons">
        <p className="control">
          <a
            id="button_login"
            className="button is-white is-rounded"
            href="https://dashboard.bergamot.app">
            Se connecter
          </a>
        </p>
        <p className="control">
          <a
            id="button_signup"
            className="button is-rounded is-outlined"
            href="https://dashboard.bergamot.app/register">
            S'inscrire
          </a>
        </p>
      </div>
    </div>
  </header>
)

export default Header
