import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/Header'
import Footer from '../components/Footer'
import ogImg from '../images/ogimage.png'

const Layout = ({ children, title, description }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImg} />
      <meta property="og:url" content={'https://www.bergamot.app'} />
      <meta property="twitter:image" content={ogImg} />
      <meta property="twitter:card" content={'summary_large_image'} />
    </Helmet>
    <div className="body-wrapper">
      <Header />
      {children}
      <Footer />
    </div>
  </>
)

export default Layout
