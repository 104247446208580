import React from 'react'
import { Link } from 'gatsby'
//import { Link, useI18next } from 'gatsby-plugin-react-i18next'

import logoGrey from '../images/logo-grey.png'

// const selectLang = (
//   <div className="select">
//     <select
//       id="lang-swichter"
//       onBlue={e => console.log(e.target.value) /*e => changeLanguage(e.target.value)*/}>
//       <option value="fr">Français</option>
//       <option value="en">English</option>
//     </select>
//   </div>
// )

const Footer = () => {
  //const { languages, changeLanguage } = useI18next()
  return (
    <footer className="footer">
      <div className="footer__left">
        <img className="footer__logo" src={logoGrey} alt="Bergamot Title" />
        <div className="footer__madewhith">
          Made with{' '}
          <span role="img" aria-label="lemon">
            🍋
          </span>{' '}
          in France
        </div>
      </div>
      <div className="footer__right">
        <div className="field is-grouped footer__buttons">
          <p className="control">
            <Link className="button is-white is-rounded" to="/privacy">
              Privacy
            </Link>
          </p>
          <p className="control">
            <Link className="button is-white is-rounded" to="/terms">
              Terms of service
            </Link>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
